import styles from './MiniCard.css'

export function MiniCard({ children, layoutClassName = undefined, tag = undefined, onClick = undefined, href = undefined }) {
  /** @type {any} */
  const Tag = tag ?? 'div'
  const hoverClass = ['a', 'button'].includes(tag) ? styles.hasHover : null

  return (
    <Tag
      className={cx(styles.component, layoutClassName, hoverClass)}
      {...{ onClick, href }}
    >
      {children}
    </Tag>
  )
}

export function MiniCardList({ layoutClassName = undefined, children }) {
  return (
    <div className={cx(styles.componentMiniCardList, layoutClassName)}>
      {children}
    </div>
  )
}
