import { useCookieConfiguration } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'

import { MiniCardList, MiniCard } from '../buildingBlocks/MiniCard'

import styles from './CookiePermissions.css'

const translations = {
  functionality_storage: {
    title: 'Functionele cookies',
    description: 'Cookies die nodig zijn om de website goed te laten werken'
  },
  analytics_storage: {
    title: 'Analytische cookies',
    description: 'Cookies om de website te kunnen verbeteren'
  },
  security_storage: {
    title: 'cookie-permission-title-security',
    description: 'cookie-permission-description-security'
  },
  ad_storage: {
    title: 'cookie-permission-title-ad',
    description: 'cookie-permission-description-ad'
  },
  personalization_storage: {
    title: 'cookie-permission-title-personalization',
    description: 'cookie-permission-description-personalization'
  },
}

export function CookiePermissions({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const availablePermissions = clientConfig.cookieConfiguration.availablePermissions
  const reportError = useReportError()

  const {
    configuration,
    updatePermission,
  } = useCookieConfiguration({
    availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {availablePermissions.map(({ name, required }) => (
        <Permission
          key={name}
          disabled={required}
          value={required || configuration?.permissions?.[name] === 'granted'}
          onChange={value => { updatePermission(name, value) }}
          title={translations[name].title}
        >
          <p>{translations[name].description}</p>
        </Permission>
      ))}
    </div>
  )
}

function Permission({ title, value, onChange, disabled, children }) {
  return (
    <div className={cx(styles.componentPermission, disabled && styles.isDisabled)} key={value}>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={value}
          onChange={() => onChange(!value)}
          className={styles.checkbox}
          {...{ disabled }}
        />
        <div>
          <strong>
            {title}
          </strong>

          {children && <div className={styles.description}>{children}</div>}
        </div>
      </label>
    </div>
  )
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
